import React from 'react'
import { withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews'
import { linkResolver } from '@/prismic/utils/linkResolver'

/**
 * Prismic Previews
 *
 * @link https://prismic.io/docs/technologies/previews-gatsby
 */

const PreviewPage = () => {
  return (
    <div
      style={{
        position: 'fixed',
        top: '10px',
        left: '10px',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        color: '#ffffff',
        zIndex: '9999',
        padding: '0.5em 0.75em',
        fontSize: '20px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        borderRadius: '6px',
      }}
    >
      Loading preview...
    </div>
  )
}

export default withPrismicPreviewResolver(PreviewPage, {
  repositoryName: process.env.GATSBY_PRISMIC_REPO,
  linkResolver,
})
